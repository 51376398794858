import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SpeakeasyExhibition from '../../components/speakeasyExhibition/SpeakeasyExhibition'
import './styles.scss'

const SpeakeasyPage = ({pageContext}) => {
  const data = pageContext.content

  const [isEmailEntered, setIsEmailEntered] = useState(false)

  const handleVisible = () => {
    setIsEmailEntered(true)
  }

  useEffect(() => {
    document.body.classList.add('dark-theme')
    if(window.localStorage.getItem('email')) {
      handleVisible(true)
    }
    return () => {
      document.body.classList.remove('dark-theme')
    }
  }, [])

  return (
    <Layout pageclass='speakeasy'>
      <Seo title="Speakeasy" />
      <div>
        {/* 当前展览 */}
        <div className='speakeasy-current'>
          {data.map((node, index) => node.node.isCurrent ?
          <div key={index}>
            <div className='speakeasy-current__title'>{node.node.thumbnailTitle}</div>
            <div className='speakeasy-current__date'>{node.node.date}</div>
            <SpeakeasyExhibition data={node.node} visible={isEmailEntered} handleVisible={handleVisible} />
          </div> : null)}
        </div>
        {/* 以往展览 */}
        <div className='speakeasy-past'>
          {data.map((node, index) => node.node.isCurrent ? null : <SpeakeasyExhibition key={index} pastSpeakeasy={true} data={node.node} visible={isEmailEntered} handleVisible={handleVisible} />)}
        </div>
      </div>
    </Layout>
  )
}

export default SpeakeasyPage
