// extracted by mini-css-extract-plugin
export var bgBlack = "styles-module--bgBlack--95c1a";
export var blink = "styles-module--blink--5a350";
export var blinker = "styles-module--blinker--59093";
export var button = "styles-module--button--7632e";
export var countDown = "styles-module--countDown--5afa2";
export var date = "styles-module--date--291d5";
export var description = "styles-module--description--61bd2";
export var errorMsg = "styles-module--errorMsg--cd36c";
export var image = "styles-module--image--25510";
export var imageHidden = "styles-module--imageHidden--868de";
export var info = "styles-module--info--e2ea5";
export var innercontainer = "styles-module--innercontainer--2a243";
export var input = "styles-module--input--388a1";
export var inputContainer = "styles-module--inputContainer--7c183";
export var inputContainerInner = "styles-module--inputContainerInner--c97ec";
export var pastContainer = "styles-module--pastContainer--0f71e";
export var text = "styles-module--text--af657";
export var title = "styles-module--title--2f430";