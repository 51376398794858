import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import * as styles from "./styles.module.scss"

const SpeakeasyExhibition = props => {
  const { data } = props

  // 	const releaseTime = 'May 02, 2022 00:00:00'
  const releaseTime = data.releaseTime

  const [days, setDays] = useState("")
  const [hours, setHours] = useState("")
  const [minutes, setMinutes] = useState("")
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    updateTime()

    setInterval(() => {
      updateTime()
    }, 1000)
  }, [])

  const releaseTimeFormatted = new Date(releaseTime).getTime()

  const updateTime = () => {
    const now = new Date().getTime()
    // Find the distance between now and the count down date
    const distance = releaseTimeFormatted - now
    if (distance < 0) {
      setIsVisible(true)
      return
    }

    const days = distance / (1000 * 60 * 60 * 24)
    setDays(Math.floor(days))
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    setHours(hours)
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    setMinutes(minutes)
  }

  return (
    <div className={`grid-parent ${styles.pastContainer}`}>
      {
        // !pastSpeakeasy ?
        // <div className={styles.innercontainer}>
        // 	<Link to={`${data.useTemplate ? '/speakeasy/' : '/c-speakeasy/'}${data.slug}`}>
        // 		<div className={styles.text}>
        // 			<div className={styles.thumbnailTitle}>{data.thumbnailTitle}</div>
        // 			<div className={styles.date}>{data.date}</div>
        // 		</div>
        // 	</Link>
        // </div>

        // :
        // 倒计时缩略图
        <div className={styles.innercontainer}>
          <div className={styles.text}>
            <Link
              className={styles.text}
              to={`${data.useTemplate ? "/speakeasy/" : "/c-speakeasy/"}${
                data.slug
              }`}
            >
              <div className={styles.title}>{data.thumbnailTitle}</div>
              <div className={styles.date}>{data.date}</div>
            </Link>
          </div>
          <div
            className={`${styles.image} ${!isVisible && styles.imageHidden}`}
          >
            <div
              className={`${styles.inputContainer} bgImage`}
              style={{
                backgroundImage: isVisible
                  ? `url(${data.featuredImage.file.url})`
                  : "none",
              }}
            >
              <div
                className={styles.inputContainerInner}
                style={{ display: "block" }}
              >
                {!isVisible && (
                  <div className={styles.countDown} style={{ color: "white" }}>
                    {days}D <span className={styles.blink}>:</span> {hours}H{" "}
                    <span className={styles.blink}>:</span> {minutes}M
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default SpeakeasyExhibition
